// src/components/Get.js
import React, { useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { Link } from 'react-router-dom';
import { SITE_URL, TELEGRAM_LINK } from './config'; // Importando a constante

const tiers = [
  {
    id: 1,
    name: 'Tier 1',
    price: '$25',
    invites: 25,
    description: '[ 60 GB MEGA LINK ] CP is all 17 (all age 12-17) (with 3000+videos)',
    link: '/tier1',
    className: 'tier1',
  },
  {
    id: 2,
    name: 'Tier 2',
    price: '$35',
    invites: 55,
    description: '[ 115 GB ] CP T33n MEGA LINK age 5-17(contain t33n, tod, mom and son, dad and daughter, incest, bro and sister, rap3 and much more) (7000+ videos)',
    link: '/tier2',
    className: 'tier2',
  },
  {
    id: 3,
    name: 'Tier 3',
    price: '$60',
    invites: 95,
    description: '[ 246 GB ] CP totally different from the 115 GB one(all age 5-17, with highly clearer menu, contain more incest) (16893+ videos)',
    link: '/tier3',
    className: 'tier3',
  },
  {
    id: 4,
    name: 'Tier 4',
    price: '$77',
    invites: 150,
    description: `[ 1 TB MEGA LINK ] CP Totally different from the 246 GB one Everything above and 1 TB of t33n and cp content age 5-17 [t33n, tod, mom and son, rap3, cp, incest and much more] (VIP group access) (44171+ videos)`,
    link: '/tier4',
    className: 'tier4',
  },
  {
    id: 5,
    name: 'Tier 5',
    price: '$160',
    invites: 250,
    description: '[ 4 TB MEGA LINK ] CP totally different from the 1 TB one',
    link: '/tier5',
    className: 'tier5',
  },
  {
    id: 6,
    name: 'Tier 6',
    price: '$450',
    invites: 800,
    description: '[ 15 TB MEGA LINK ] CP totally different from the 4 TB one',
    link: '/tier6',
    className: 'tier6',
  },
];

const Get = () => {
  const { user } = useContext(UserContext);

  // Função para determinar o Tier do usuário com base no número de convites
  const getTier = (invitesCount) => {
    if (invitesCount >= 800) return 6;
    if (invitesCount >= 250) return 5;
    if (invitesCount >= 150) return 4;
    if (invitesCount >= 95) return 3;
    if (invitesCount >= 55) return 2;
    if (invitesCount >= 25) return 1;
    return 0;
  };

  const userTier = getTier(user.invitesCount);

  return (
    <div className="tier-container">
      {/* Saudação Inicial */}
      <div className="card">
        <h3 className="centermid">
          Hello, <span>{user.username}</span>! <br />
        </h3>
        <h3 className="centermid" style={{ marginTop: '5px' }}>
          You can get more videos <span className="color">buying instant access</span> or <span className="color">inviting others</span>.
        </h3>
      </div>

      {/* Mapeamento dos Tiers */}
      {tiers.map((tier) => (
        <div key={tier.id} className="tierCard">
          <h3>
            <span className={tier.className}>{tier.name}</span>
            <br />
            {tier.price} or {tier.invites} convites
            <br />
            <br />
            <span className={tier.className}>
              {tier.description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </span>
          </h3>
          <div className="tier-buttons">
            <Link to={tier.link}>
              <button className="tier">{tier.name}</button>
            </Link>
            <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
              <button className="access">Instant Access</button>
            </a>
            <a href="/getinvites">
              <button className="invite">Invite Now</button>
            </a>
          </div>
        </div>
      ))}

      {/* Opcional: Adicione mais conteúdo ou componentes aqui */}
    </div>
  );
};

export default Get;
