// src/components/Layout.js
import React, { useState, useContext } from 'react';
import '../styles/dashboard.css';
import { Outlet, Link, Navigate } from 'react-router-dom'; // Importando Navigate
import { UserContext } from '../context/UserContext';
import { SITE_URL, TELEGRAM_LINK } from './config'; // Importando a constante

const Layout = () => {
  const { user, isLoading } = useContext(UserContext); // Desestruturando isLoading
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  if (isLoading) {
    return <div>Loading...</div>; // Exibe enquanto carrega
  }

  if (!user) {
    // Redireciona para /register se o usuário não estiver autenticado
    return <Navigate to="/register" replace />;
  }

  // Removemos a função getTier, pois o backend já fornece o tier
  const userTier = user.tier; // Usando o tier diretamente do backend

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Gerar o link de convite usando invite_code (que é igual ao username)
  const inviteLink = `${SITE_URL}/register?invite=${user.invite_code}`;

  return (
    <div className="dashboard-container">
      <nav>
        <div className="navbar">
          <div className="container nav-container">
            <div className="logo">
              <form action="/logout" method="GET" style={{ display: 'inline' }}>
                <button type="submit" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  {/* Ícone de Notificação */}
                  <svg viewBox="0 0 24 24" className="svg-inline--fa fa-bell notifyIcon" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#ff0000"
                      d="M2 6.5C2 4.01472 4.01472 2 6.5 2H12C14.2091 2 16 3.79086 16 6V7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7V6C14 4.89543 13.1046 4 12 4H6.5C5.11929 4 4 5.11929 4 6.5V17.5C4 18.8807 5.11929 20 6.5 20H12C13.1046 20 14 19.1046 14 18V17C14 16.4477 14.4477 16 15 16C15.5523 16 16 16.4477 16 17V18C16 20.2091 14.2091 22 12 22H6.5C4.01472 22 2 19.9853 2 17.5V6.5ZM18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071C17.9024 15.3166 17.9024 14.6834 18.2929 14.2929L19.5858 13L11 13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L19.5858 11L18.2929 9.70711C17.9024 9.31658 17.9024 8.68342 18.2929 8.29289Z" />
                  </svg>
                </button>
              </form>
            </div>
            <div className="hamburger_menu">
              <input
                id="menu__toggle"
                type="checkbox"
                checked={isMenuOpen}
                onChange={handleMenuToggle}
              />
              <label className="menu__btn" htmlFor="menu__toggle"><span></span></label>
              <ul className="menu__box">
                <div className="profileData">
                  <h3>Username: {user.username}</h3>
                  <h4>ID: {user.id}</h4>
                  <h4>Tier: {userTier}</h4>
                  <h4>Invites: {user.invites_count}</h4> {/* Corrigido para invites_count */}
                  <a href="/getinvites">
                    <button>Invite Now</button>
                  </a>
                </div>
                <li><Link className="menu__item" to="/dashboard">Dashboard</Link></li>
                <li><Link className="menu__item" to="/preview">Free Preview</Link></li>
                <li><Link className="menu__item" to="/get">Get more video</Link></li>
                <li>
                  <Link className="menu__item" to="/invites">
                    Invites Counting
                    <span
                      style={{
                        backgroundColor: '#1DA1F2',
                        padding: '5px 10px',
                        borderRadius: '60px',
                        color: 'rgb(255, 255, 255)',
                        marginLeft: '0vh',
                        display: 'inline-block',
                      }}>
                      {user.invites_count} {/* Corrigido para invites_count */}
                    </span>
                  </Link>
                </li>
                <li><Link className="menu__item" to="/getinvites">Get fast invites</Link></li>
                <li><Link className="menu__item" to="/tier1">Tier 1</Link></li>
                <li><Link className="menu__item" to="/tier2">Tier 2</Link></li>
                <li><Link className="menu__item" to="/tier3">Tier 3</Link></li>
                <li><Link className="menu__item" to="/tier4">Tier 4</Link></li>
                <li><Link className="menu__item" to="/tier5">Tier 5</Link></li>
                <li><Link className="menu__item" to="/tier6">Tier 6</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className="bottom-buttons">
        <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
          <button className="buyNow">Buy <br />Now!</button>
        </a>
      </div>

      <div className={`info-container ${isMenuOpen ? 'menu-open' : ''}`}>
        <Outlet /> {/* Renderiza os componentes filhos */}
      </div>
    </div>
  );
};

export default Layout;
