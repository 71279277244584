// src/components/Invites.js
import React, { useState, useEffect, useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';

const Invites = () => {
  const { user } = useContext(UserContext);
  const [invites, setInvites] = useState([]);

  if (!user) {
    return <div>Loading...</div>; // ou uma mensagem de erro apropriada
  }

  // Função para gerar um convite fake
  const generateFakeInvite = () => {
    const inviterUsername = generateRandomString(8);
    const inviteeUsername = generateRandomString(8);
    const invitesCount = generateRandomInvites(10, 150);
    const tierUnlocked = getTier(invitesCount);
    return { inviterUsername, inviteeUsername, invitesCount, tierUnlocked };
  };

  // Função auxiliar para gerar strings aleatórias
  const generateRandomString = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Função auxiliar para gerar números de convites aleatórios
  const generateRandomInvites = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Função para determinar o tier baseado no número de convites
  const getTier = (invitesCount) => {
    if (invitesCount >= 800) return 6;
    if (invitesCount >= 250) return 5;
    if (invitesCount >= 150) return 4;
    if (invitesCount >= 95) return 3;
    if (invitesCount >= 55) return 2;
    if (invitesCount >= 25) return 1;
    return 0;
  };

  // Função para exibir um convite na lista
  const displayInvite = (invite) => {
    setInvites((prevInvites) => [invite, ...prevInvites]);
  };

  // Simula a geração de convites a cada 2 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      const fakeInvite = generateFakeInvite();
      displayInvite(fakeInvite);
    }, 1000);

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);

  return (
    <div className="invites-page">
      <div className="invites-container" id="invites-container">
        {invites.map((invite, index) => (
          <div key={index} className="invite-card">
            <p>
              <span className="color">{invite.inviterUsername}</span> has invited{' '}
              <span className="color">{invite.inviteeUsername}</span> [{invite.invitesCount} invites]
            </p>
            {invite.tierUnlocked > 0 && (
              <p className="tier-unlocked">
                <span className="color">{invite.inviteeUsername}</span> unlocked <span className="color">Tier {invite.tierUnlocked}</span>!
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Invites;
