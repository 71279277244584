// src/components/GetInvites.js
import React, { useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { SITE_URL } from './config';

const GetInvites = () => {
  const { user } = useContext(UserContext); // Acessa o contexto UserContext diretamente

  if (!user) {
    return <div>Loading...</div>; // Exibe uma mensagem enquanto o user não está disponível
  }

  // Use os nomes de campo corretos conforme o backend (snake_case)
  const inviteLink = `${SITE_URL}/register?invite=${user.invite_code}`;

  return (
    <div className="info-container menu-open">
      <div className="card">
        <h3>
          You have <span className="color">{user.invites_count}</span> invitations, you need <span className="color">25</span> invitations to access <span className="color">Tier 1</span>
        </h3>
        <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Your Invitation Link</h3>
        <input
          type="text"
          readOnly
          id="inviteLink"
          value={inviteLink}
          onClick={(e) => e.target.select()}
        />
        <button
          className="copyButton"
          onClick={() => {
            navigator.clipboard.writeText(inviteLink)
              .then(() => alert("Link copied to clipboard!"))
              .catch(err => console.error("Failed to copy text:", err));
          }}
        >
          Copy Link
        </button>
        <h4>
          Share your invite link with your friends, or <span className="green">spam your invitation</span> em <span className="color">reddit</span> or <span className="color">twitter</span> with keywords...
        </h4>
      </div>
    </div>
  );
};

export default GetInvites;
