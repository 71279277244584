// src/components/Captcha.js
import React, { useEffect, useState } from 'react';
import { API_URL } from './config'; // Importa o API_URL do config.js

const Captcha = ({ onUserInputChange }) => {
    const [num1, setNum1] = useState(null);
    const [num2, setNum2] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCaptcha();
    }, []);

    const fetchCaptcha = () => {
        fetch(`${API_URL}/captcha`, {
            method: 'GET',
            credentials: 'include', // Inclui cookies, se necessário
        })
            .then(response => response.json())
            .then(data => {
                setNum1(data.num1);
                setNum2(data.num2);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar captcha:', error);
                setLoading(false);
            });
    };

    const refreshCaptcha = () => {
        setLoading(true);
        fetchCaptcha();
    };

    const handleChange = (e) => {
        const value = e.target.value;
        onUserInputChange(value);
    };

    return (
        <div className="captcha-container">
            {loading ? (
                <p>Carregando captcha...</p>
            ) : (
                <>
                    <div className="captcha-question">
                        {num1} + {num2} = ?
                    </div>
                    <button type="button" onClick={refreshCaptcha} title="Recarregar Captcha">🍭</button>
                    <input
                        type="text"
                        name="captcha"
                        placeholder="Digite a resposta"
                        onChange={handleChange}
                        required
                    />
                </>
            )}
        </div>
    );
};

export default Captcha;
