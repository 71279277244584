// src/config.js

// Obter o domínio atual dinamicamente para o frontend
export const SITE_URL = window.location.origin;

// URL da API backend
export const API_URL = 'https://contentexclusive.shop'; // Atualize para o endereço real da sua API

// Link do Telegram
export const TELEGRAM_LINK = 'https://t.me/ihaveuwantv';

// Outros links ou constantes que você deseja reutilizar
