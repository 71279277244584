// src/components/tier5.js
import React, { useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { TELEGRAM_LINK, SITE_URL } from './config'; // Importando as constantes

const Tier5 = () => {
  const { user } = useContext(UserContext);
  const inviteThreshold = 250;

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tier-container">
      <div className="tierCard">
        <h3>
          <span className="tier5">Tier 5</span><br />
          $100 or 250 invites<br /><br />
          <span className="tier5">
            [ 4 TB MEGA LINK ] CP totally different from the 1 TB one
          </span>
        </h3>
        <div className="tier-buttons">
          <a href="/tier5">
            <button className="tier">Tier 5</button>
          </a>
          <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
            <button className="access">Instant Acces</button>
          </a>
          <a href="/getinvites">
            <button className="invite">Invite Now</button>
          </a>
        </div>
      </div>

      {user.invitesCount >= inviteThreshold ? (
        <div className="card">
          <h3>
            <span className="color">{user.username}</span> you have <span className="color">{user.invitesCount}</span> invites!
          </h3>
          <h3>tier5</h3><br />
          <h3>
            To access exclusive content, contact us via our <span className="color">Telegram</span>:
          </h3>
          <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
            <button className="access">Access on Telegram</button>
          </a>
        </div>
      ) : (
        <div className="card">
          <h3>
            <span className="color">{user.username}</span>, You don't have enough invites to access <span className="color">Tier 5</span>. Your number of invitations is <span className="color">{user.invitesCount}</span>.
          </h3><br />
          <h3>
          You need  <span className="color">250 invites</span> to access this tier. If you have enough invites, refresh the page to automatically unlock.
          </h3>
          <h3>
            To check your invitations, click here:
          </h3>
          <a href="/invites">
            <button>Invitation Count</button>
          </a>
        </div>
      )}

      <div className="card">
        <h3>If you want to check your invitations, click here</h3>
        <a href="/invites">
          <button>Invitation Count</button>
        </a>
      </div>
    </div>
  );
};

export default Tier5;
