// src/components/Dashboard.js
import React, { useState, useRef, useContext, useEffect } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { SITE_URL, TELEGRAM_LINK, API_URL } from './config'; // Importando a constante

const Dashboard = () => {
  const { user, token, setUser } = useContext(UserContext);
  const inviteLinkRef = useRef(null);
  const [loading, setLoading] = useState(true);

  // Função para buscar os dados do perfil do usuário
  const fetchProfile = async () => {
    try {
      const response = await fetch(`${API_URL}/profile`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include', // Inclui cookies, se necessário
      });

      const result = await response.json();

      if (response.ok) {
        setUser(result);
      } else {
        console.error('Erro ao buscar perfil:', result.msg);
      }
    } catch (error) {
      console.error('Erro ao buscar perfil:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchProfile();
    } else {
      setLoading(false);
    }

  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Você não está autenticado. Por favor, <a href="/">faça login</a>.</div>;
  }

  const getTier = (tier) => {
    return tier;
  };

  const copyInviteLink = () => {
    if (inviteLinkRef.current) {
      inviteLinkRef.current.select();
      inviteLinkRef.current.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(inviteLinkRef.current.value)
        .then(() => {
          alert("Link copiado para a área de transferência!");
        })
        .catch(err => {
          console.error("Falha ao copiar o texto: ", err);
        });
    }
  };

  return (
    <div className="dashboard-content">
      <div className="profileData">
        <h3>Username: {user.username}</h3>
        <h4>ID: {user.id}</h4>
        <h4>Tier: {getTier(user.tier)}</h4>
        <h4>Invites: {user.invites_count}</h4>
      </div>
      <div className="card">
        <h3 className="center">Hello <span className="color">{user.username}</span></h3>
        <h3>Always check our status site to see which site we are on.</h3>
        <a href={SITE_URL} target="_blank" rel="noopener noreferrer">
          <button>Enter Website</button>
        </a>
        <h3>If you don't know how to view more content:</h3>
        <a href="/get">
          <button>Get more video</button>
        </a>
        <h3>If you want to check your invite:</h3>
        <a href="/getinvites">
          <button>Get invite</button>
        </a>
      </div>
      <div className="card">
        <h3>
          You have <span className="color">{user.invites_count}</span> invites, you need <span className="color">25</span> invites to access <span className="color">Tier 1</span>
        </h3>
        <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Your Invite Link</h3>
        <input
          type="text"
          readOnly
          id="inviteLink"
          value={`${SITE_URL}/register?invite=${user.invite_code}`} // Inclui o parâmetro 'invite'
          ref={inviteLinkRef}
        />
        <button className="copyButton" onClick={copyInviteLink}>Copy link</button>
        <h4>
          Share your invite link to your friends, or <span className="green">spam your invite</span> in <span className="color">reddit</span> or <span className="color">twitter</span>...
        </h4>
      </div>
    </div>
  );
};

export default Dashboard;
