import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import Register from './components/register';
import Dashboard from './components/dashboard';
import Get from './components/Get';
import GetInvites from './components/GetInvites';
import Tier1 from './components/Tier1';
import Tier2 from './components/Tier2';
import Tier3 from './components/Tier3';
import Tier4 from './components/Tier4';
import Tier5 from './components/Tier5';
import Tier6 from './components/Tier6';
import Layout from './components/layout';
import Preview from './components/Preview';
import Invites from './components/Invites';



const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rotas Públicas */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Navigate to="/" replace />} /> {/* Redirecionamento */}
        <Route path="/register" element={<Register />} />

        {/* Rotas Protegidas com Layout */}
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/get" element={<Get />} />
          <Route path="/getinvites" element={<GetInvites />} />
          <Route path="/invites" element={<Invites />} />
          <Route path="/preview" element={<Preview />} />
          <Route path="/tier1" element={<Tier1 />} />
          <Route path="/tier2" element={<Tier2 />} />
          <Route path="/tier3" element={<Tier3 />} />
          <Route path="/tier4" element={<Tier4 />} />
          <Route path="/tier5" element={<Tier5 />} />
          <Route path="/tier6" element={<Tier6 />} />
          {/* Adicione outras rotas protegidas conforme necessário */}
        </Route>
      </Routes>
    </Router>
  );
};


export default App;