// src/components/Register.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import '../styles/global.css';
import Captcha from './captcha'; // Verifique se o caminho está correto
import { TELEGRAM_LINK, SITE_URL, API_URL } from './config';
import { UserContext } from '../context/UserContext';

const Register = () => {
  const [message, setMessage] = useState(null);
  const [captchaInput, setCaptchaInput] = useState('');
  const navigate = useNavigate();
  const { setUser, setToken } = useContext(UserContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteCode = queryParams.get('invite'); // Extrai 'invite' da URL, ex: /register?invite=username123

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      username: formData.get('username'),
      password: formData.get('password'),
      captcha: formData.get('captcha'),
      inviteCode: inviteCode || null, // Passa o inviteCode se existir
    };

    try {
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Inclui cookies, se necessário
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        // Armazena o token e os dados do usuário no contexto
        setToken(result.access_token);
        setUser(result.user);

        // Exibe uma mensagem de sucesso e navega para o dashboard
        setMessage({ type: 'success', text: 'Registration successful!' });
        setTimeout(() => {
          setMessage(null);
          navigate('/dashboard');
        }, 2000);
      } else {
        // Exibe a mensagem de erro retornada pela API
        setMessage({ type: 'error', text: result.msg || 'Error in registration.' });
        setTimeout(() => setMessage(null), 3000);
      }
    } catch (error) {
      console.error('Erro ao registrar:', error);
      setMessage({ type: 'error', text: 'Erro ao conectar com o servidor.' });
      setTimeout(() => setMessage(null), 3000);
    }
  };

  // Opcional: Mostrar uma mensagem de erro se o inviteCode estiver faltando
  useEffect(() => {
    if (location.search && !inviteCode) {
      setMessage({ type: 'error', text: 'Invalid invitation code.' });
    }
  }, [inviteCode, location.search]);

  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="twitter-container">
          <div className="twitter">
            {/* Botão x-back sem ação */}
            <button type="button" className="x-back">←</button>
            <div className="twitter-buttons">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://twitter.com/intent/tweet?text=Caldo%20De%20Pollo:%20${encodeURIComponent(SITE_URL)}`}
              >
                <button className="x-post">Post</button>
              </a>
            </div>
          </div>
          <div className="twitter-inline">
            <div
              className="twitter-avatar"
              style={{
                backgroundImage: "url('https://i.imgur.com/44RTLzf.png')",
              }}
            ></div>
            <div className="twitter-text">
              <h3>#CaldoDePollo #Pxdo #Lxli #Under4ge</h3>
              <h3>
                <a
                  href={SITE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#2596be', textDecoration: 'none', fontSize: '30px' }}
                >
                  {SITE_URL}
                </a>
              </h3>
            </div>
          </div>
        </div>

        <div className="form-container">
          <h2>Register</h2>
          <form onSubmit={handleSubmit} id="registerForm">
            <input type="text" name="username" placeholder="Username" required />
            <input type="password" name="password" placeholder="Password" required />
            <Captcha onUserInputChange={setCaptchaInput} />
            {/* Removido o campo de inviteCode */}
            <button type="submit">Register</button>
          </form>

          {message && (
            <div className={`message-container ${message.type === 'success' ? 'success-message' : 'error-message'}`}>
              {message.text}
            </div>
          )}

          <p>Already have an account? <Link to="/">login here</Link>.</p>
        </div>
      </div>
    </div>
  );
};

export default Register;
