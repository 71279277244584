// src/components/Preview.js
import React from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { useContext } from 'react';

const Preview = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="preview-page">
      <div className="card">
        <h3 className="centermid">
          Hello, <span>{user.username}</span>! <br />
        </h3>
        <h3 className="centermid" style={{ marginTop: '5px' }}>
          Here's a free preview of our videos!
        </h3>
      </div>

      <div className="preview-container">
        <div className="video-card">
          <video controls>
            <source src="https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126196/c6ijy838hhsuas5t8ayj.mp4" type="video/mp4" />
            Your browser does not support the video element.
          </video>
          <p>PREVIEW 1</p>
        </div>

        <div className="video-card">
          <video controls>
            <source src="https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126190/vyabgg29p0wnvb7dofo2.mp4" type="video/mp4" />
            Your browser does not support the video element.
          </video>
          <p>PREVIEW 2</p>
        </div>

        <div className="video-card">
          <video controls>
            <source src="https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126190/v4egwqflqcqpzfpvdrga.mp4" type="video/mp4" />
            Your browser does not support the video element.
          </video>
          <p>PREVIEW 3</p>
        </div>

        <div className="video-card">
          <video controls>
            <source src="https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126190/hxhxoalah5havppdk2hm.mp4" type="video/mp4" />
            Your browser does not support the video element.
          </video>
          <p>PREVIEW 4</p>
        </div>
      </div>
    </div>
  );
};

export default Preview;
